import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import PictureGallery from './pages/PictureGallery'
import SecondaryPage from './components/SecondaryPage'
import Error404 from './pages/Error404'
import secondaryPageData from './constants/secondary-pages'

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="realisations" element={<PictureGallery />} />
      {secondaryPageData.map((route) => (
        <Route key={route.link} path={route.link} element={<SecondaryPage {...route.data} />} />
      ))}
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default Router
