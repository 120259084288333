import React from 'react'
import { RiCheckLine } from 'react-icons/ri'
import { checkedList } from '../constants/checkedlist'
import { motion } from 'framer-motion'

const infoVariants = {
  hidden: { y: -30, opacity: 0 },
  show: { y: 0, opacity: 1 }
}

const Elements = (d) => {
  return (
    <motion.li
      variants={infoVariants}
      className="flex flex-row gap-px"
    >
      <RiCheckLine fill='green' className='my-auto' size={20} />
      <p>{d.text}</p>
    </motion.li>
  )
}

const wrapperVariants = {
  show: {
    transition: { staggerChildren: 0.25 }
  }
}

const CheckedList = () => {
  return (
    <motion.ul
      variants={wrapperVariants}
      initial='hidden'
      animate='show'
      className='flex flex-col z-10 text-white md:text-lg sm:text-base text-sm'
    >
      {checkedList.map(element => (
        <Elements key={element.ielement} {...element} />
      ))}
    </motion.ul>
  )
}

export default CheckedList
