import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import faqQuestions from '../constants/faq-questions'

const arrowVariants = {
  open: {
    rotate: 180,
  },
  close: {
    rotate: 0,
  }
}

const QuestionAnswer = ({ question, answer }) => {
  const [isClicked, setIsClicked] = useState(false)
  const handleClick = () => {
    setIsClicked(prev => !prev)
  }
  return (
    <div className="border-b-[1px] border-b-slate-300">
      <button
        className="flex w-full items-center justify-between gap-4 py-6"
        onClick={handleClick}
      >
        <span
          className={`text-left text-lg font-medium ${isClicked ? 'text-slate-300' : 'text-white'}`}
        >
          {question}
        </span>
        <motion.span
          variants={arrowVariants}
          animate={isClicked ? 'open': 'close' }
        >
          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-2xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <polyline points="6 9 12 15 18 9" />
          </svg>
        </motion.span>
      </button>
      <AnimatePresence>
        {isClicked &&
          <motion.div
            initial={{ height: 0, marginBottom: 0 }}
            animate={{ height: "fit-content", marginBottom: 24 }}
            exit={{ height: 0, marginBottom: 0 }}
            className="overflow-hidden text-slate-100"
          >
            <p>{answer}</p>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}

const FAQs = () => {
  return (
    <div className="no-scrollbar relative w-full overflow-hidden" style={{ display: "block" }}>
      <div className="px-4 py-12">
        <div className="mx-auto max-w-3xl">
          <h3 className="mb-4 text-center text-3xl font-semibold">
            Questions fréquentes
          </h3>
          {faqQuestions.map(question => (
            <QuestionAnswer key={question.id} {...question} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQs
