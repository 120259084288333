export const realisationSection = {
  title: "Réalisations",
  subtitle: "Découvrez par vous même le resultat d'un travail bien fait",
  routes: [
    {
      title: "Maison connectée",
      links: [
        { to: "/domotique", text: "Solutions Domotique" },
        { to: "/telecommandes", text: "Telecommandes" },
        { to: "/digicodes", text: "Digicodes" },
        { to: "/alarmes", text: "Alarmes" }
      ]
    },
    {
      title: "Motorisations",
      links: [
        { to: "/portes-garage", text: "Portes de Garage" },
        { to: "/volets-roulants", text: "Volets Roulants" },
        { to: "/stores", text: "Stores"  }
      ]
    },
    {
      title: "Fermetures",
      links: [
        { to: "/fenetres", text: "Fenêtres & Velux" },
        { to: "/portes-entrees", text: "Portes d'Entrée" },
        { to: "/verandas", text: "Vérandas" },
        { to: "/pergolas", text: "Pergolas" },
      ]
    }
  ]
};
