import { Helmet } from 'react-helmet-async'
import SwipeCarousel from './SwipeCarousel'
import { motion } from 'framer-motion'

const SecondaryPage = (data) => {
  window.scrollTo(0, 0)
  return (
    <>
      <Helmet>
        <title>Fermetures Sans-souci - {data.helmet.title}</title>
        <meta name="description" content={data.helmet.description} />
        <meta name="keywords" content={data.helmet.keywords} />
      </Helmet>
      <div className='pt-44 pb-10'>
        <motion.h1
          className='text-xl text-white sm:text-3xl md:text-5xl w-max mx-auto skew-x-12'
          aria-label={data.title}
        >
          {data.title}
        </motion.h1>
        <hr className='border-b-px border-indigo-600 w-24 md:w-32 mt-3 mb-10 m-auto' aria-label="Divider" />
        <p className='max-w-5xl mx-auto md:text-lg px-4 sm:px-10'>
          {data.helmet.description}
        </p>
        <SwipeCarousel images={data.images.map(el => el.filename)} />
        <p className='max-w-5xl mx-auto md:text-lg px-4 sm:px-10'>{data.description}</p>
      </div>
    </>
  )
}

export default SecondaryPage
