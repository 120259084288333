import React from 'react'
import partners from '../constants/partners'
import { imagePath } from '../helpers/helpers'
import { motion } from 'framer-motion'

const SkillSlider = ({ direction, duration, children }) => {
  return (
    <div className='py-3 w-full text-white'>
      <div className='flex mx-5'>
        {Array.from({ length: 3 }).map((x) => (
          <motion.div
            key={x}
            animate={{ x: direction }}
            transition={{ ease: 'linear', repeat: Infinity, duration }}
            className='flex lg:gap-10 gap-3 px-3'
          >
            {children}
          </motion.div>
        ))}
      </div>
    </div>
  )
}

const Partners = () => {
  return (
    <div id='parteners' className='xl:max-w-7xl lg:max-w-5xl mx-auto rounded overflow-hidden bg-slate-800/70 pb-10 space-y-3'>
      <SkillSlider direction={['0%', '-100%']} duration={25}>
        {partners.map(partner => (
          <img
            key={partner.id}
            src={imagePath(`logos/${partner.filename}`, 'svg')}
            alt={partner.alt}
            loading='lazy'
            className='max-w-none w-[80px] sm:w-[100px] lg:w-[150px]'
          />
        ))}
      </SkillSlider>
      <SkillSlider direction={['-100%', '0%']} duration={25}>
        {partners.map(partner => (
          <img
            key={partner.id}
            src={imagePath(`logos/${partner.filename}`, 'svg')}
            alt={partner.alt}
            loading='lazy'
            className='max-w-none w-[80px] md:w-[100px] lg:w-[150px]'
          />
        ))}
      </SkillSlider>
    </div>
  )
}

export default Partners
