export const ImageType = {
  FENETRE: "FENETRE",
  PORTAIL: "PORTAIL",
  PORTEENTREE: "PORTEENTREE",
  PORTEGARAGE: "PORTEGARAGE",
  VERANDA: "VERANDA",
  VOLETROULANT: "VOLETROULANT",
  PERGOLA: "PERGOLA",
  STORE: "STORE",
  TELECOMMANDE: "TELECOMMANDE",
  ALARME: "ALARME",
  DIGICODE: "DIGICODE",
  DOMOTIQUE: 'DOMOTIQUE'
};

const galeryImages = [
  {
    type: ImageType.FENETRE,
    name: "fenêtre",
    images: [
      {
        filename: "velux_1",
        alt: "Image d'une fenêtre"
      },
      {
        filename: "fenetre_1",
        alt: "Image d'une fenêtre"
      },
      {
        filename: "velux_2",
        alt: "Image d'une fenêtre"
      },
      {
        filename: "velux_3",
        alt: "Image d'une fenêtre"
      },
      {
        filename: "fenetre_2",
        alt: "Image d'une fenêtre"
      },
      {
        filename: "fenetre_3",
        alt: "Image d'une fenêtre"
      },
      {
        filename: "fenetre_4",
        alt: "Image d'une fenêtre"
      },
      {
        filename: "fenetre_5",
        alt: "Image d'une fenêtre"
      }
    ],
  },
  {
    type: ImageType.PORTAIL,
    name: "portails",
    images: [
      {
        filename: "portail_1",
        alt: "Image d'un portail"
      },
      {
        filename: "portail_2",
        alt: "Image d'un portail"
      },
      {
        filename: "portail_3",
        alt: "Image d'un portail"
      }
    ],
  },
  {
    type: ImageType.PORTEENTREE,
    name: "portes d'entrée",
    images: [
      {
        filename: "porte_entree_1",
        alt: "Image d'une porte d'entrée"
      },
      {
        filename: "porte_entree_2",
        alt: "Image d'une porte d'entrée"
      }
    ],
  },
  {
    type: ImageType.PORTEGARAGE,
    name: "portes de garage",
    images: [
      {
        filename: "porte_garage_1",
        alt: "Image d'une porte de garage"
      },
      {
        filename: "porte_garage_2",
        alt: "Image d'une porte de garage"
      },
      {
        filename: "porte_garage_3",
        alt: "Image d'une porte de garage"
      },
      {
        filename: "porte_garage_4",
        alt: "Image d'une porte de garage"
      },
      {
        filename: "porte_garage_5",
        alt: "Image d'une porte de garage"
      },
      {
        filename: "porte_garage_6",
        alt: "Image d'une porte de garage"
      }
    ],
  },
  {
    type: ImageType.VERANDA,
    name: "verandas",
    images: [
      {
        filename: "veranda_1",
        alt: "Image d'une veranda"
      },
      {
        filename: "veranda_2",
        alt: "Image d'une veranda"
      },
      {
        filename: "veranda_3",
        alt: "Image d'une veranda"
      },
      {
        filename: "veranda_4",
        alt: "Image d'une veranda"
      },
      {
        filename: "veranda_5",
        alt: "Image d'une veranda"
      }
    ],
  },
  {
    type: ImageType.VOLETROULANT,
    name: "volets roulants",
    images: [
      {
        filename: "volet_roulant_1",
        alt: "Image d'un volet roulant"
      },
      {
        filename: "volet_roulant_2",
        alt: "Image d'un volet roulant"
      },
      {
        filename: "volet_roulant_3",
        alt: "Image d'un volet roulant"
      },
      {
        filename: "volet_roulant_4",
        alt: "Image d'un volet roulant"
      },
      {
        filename: "volet_roulant_5",
        alt: "Image d'un volet roulant"
      },
      {
        filename: "volet_roulant_6",
        alt: "Image d'un volet roulant"
      },
      {
        filename: "volet_roulant_7",
        alt: "Image d'un volet roulant"
      },
      {
        filename: "volet_roulant_8",
        alt: "Image d'un volet roulant"
      }
    ],
  },
  {
    type: ImageType.PERGOLA,
    name: "pergolas",
    images: [
      {
        filename: "pergola_1",
        alt: "Image d'une pergola"
      },
      {
        filename: "pergola_2",
        alt: "Image d'une pergola"
      }
    ],
  },
  {
    type: ImageType.ALARME,
    name: "alarmes",
    images: [
      {
        filename: "pergola_1",
        alt: "Image d'une pergola"
      },
      {
        filename: "pergola_2",
        alt: "Image d'une pergola"
      }
    ],
  },
  {
    type: ImageType.STORE,
    name: "stores",
    images: [
      {
        filename: "store_1",
        alt: "Image d'un store"
      },
      {
        filename: "store_2",
        alt: "Image d'un store"
      },
      {
        filename: "store_3",
        alt: "Image d'un store"
      },
      {
        filename: "store_4",
        alt: "Image d'un store"
      }
    ],
  },
  {
    type: ImageType.TELECOMMANDE,
    name: "telecommandes",
    images: [
      {
        filename: "telecommande_1",
        alt: "Image d'une telecommande"
      },
      {
        filename: "telecommande_2",
        alt: "Image d'une telecommande"
      },
      {
        filename: "telecommande_3",
        alt: "Image d'une telecommande"
      }
    ],
  },
  {
    type: ImageType.DOMOTIQUE,
    name: "domotique",
    images: [
      {
        filename: "telecommande_1",
        alt: "Image d'une telecommande"
      },
      {
        filename: "telecommande_2",
        alt: "Image d'une telecommande"
      },
      {
        filename: "telecommande_3",
        alt: "Image d'une telecommande"
      }
    ],
  },
  {
    type: ImageType.DIGICODE,
    name: "digicode",
    images: [
      {
        filename: "telecommande_1",
        alt: "Image d'une telecommande"
      },
      {
        filename: "telecommande_2",
        alt: "Image d'une telecommande"
      },
      {
        filename: "telecommande_3",
        alt: "Image d'une telecommande"
      }
    ],
  }
];

export default galeryImages;
