const partners = [
  {
    id: 1,
    filename: 'batistore',
    alt: 'logo de la marque Batistore'
  },
  {
    id: 2,
    filename: 'somfy',
    alt: 'logo de la marque Somfy'
  },
  {
    id: 3,
    filename: 'janneau',
    alt: 'logo de la marque Janneau'
  },
  {
    id: 4,
    filename: 'gp-portail',
    alt: 'logo de la marque GP-Portail'
  },
  {
    id: 5,
    filename: 'bubendorff',
    alt: 'logo de la marque Bubendordd'
  },
  {
    id: 6,
    filename: 'velux',
    alt: 'logo de la marque Velux'
  }
];

export default partners;
