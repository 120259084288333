const faqQuestions = [
  {
    id: 1,
    question: "Comment prendre contact avec Fermetures Sans-souci ?",
    answer: <>Fermetures Sans-souci est joignable par téléphone au <a href='tel:0160889494'><strong>01.60.88.94.94</strong></a> ou par e-mail à l'adresse suivante: <a href='mailto:fermetures.sans.souci@gmail.com'><strong>fermetures.sans.souci@gmail.com</strong></a>.</>
  },
  {
    id: 2,
    question: "Quel est le secteur d'intervention de Fermetures Sans-souci ?",
    answer: <>Fermetures Sans-souci intervient dans tout l'Île de France.</>
  },
  {
    id: 3,
    question: "Quels sont les avis des internautes à propos de Fermetures Sans-souci ?",
    answer: <>D'après les avis Google Maps, les internautes sont très satisfait par l'intervention de Fermetures Sans-souci.</>
  },
  {
    id: 4,
    question: "Quelle est l'adresse de Fermetures Sans-souci ?",
    answer: <>Fermetures Sans-souci est situé au 75 Fontainebleau, 91100 Corbeil Essonnes.</>
  }
];

export default faqQuestions;
