import React from 'react'
import ScrollContent from '../components/ScrollContent'
import Partners from '../components/Partners'
import TextSection from '../components/TextSection'
import Reviews from '../components/Reviews'
import { Helmet } from 'react-helmet-async'
import FAQs from '../components/FAQs'
import { getDiffYear } from '../helpers/helpers'
import contacts from '../constants/contacts'
import HeroImageScroll from '../components/HeroImageScroll'
import { motion } from 'framer-motion'

const Home = () => {
  window.scrollTo({ top: 0 })
  return (
    <>
      <Helmet>
        <title>Fermetures Sans-souci - Votre professionnel de la fermeture en Essonnes</title>
        <meta name="description" content="Devis gratuit 100% gratuit ! Installation et paramétrage d'alarmes et de systèmes domotiques. Remplacement de vitres et vitrines. Pose, réglage et réparation de store et volet roulant. Intervention en urgence 24/24." />
        <meta name="keywords" content="fenetre corbeil essonnes, store corbeil essonnes, pergola corbeil essonnes, velux corbeil essonnes, alarme corbeil essonnes, domotique corbeil essonnes, volet corbeil essonnes, volet roulant corbeil essonnes" />
      </Helmet>
      <HeroImageScroll />
      <TextSection
        id='company'
        title="Fermetures Sans-souci"
        text={`Votre spécialiste de la fermeture en Essonne ! 
          Confiez-nous votre projet et profitez de nos ${getDiffYear(1998)} années d'expériences 
          pour assurer l'accomplissement de votre projet.
          Forts de notre expérience dans la fermeture, la motorisation et les systèmes de sécurité
          , nous vous garantissons une prestations soignées réalisées dans le respect des 
          délais pour vous apporter entière satisfaction. Nous sommes à votre écoute !`}
      />
      <ScrollContent />
      <TextSection
        id='partners'
        title='Nos Partenaires'
        text="Voici un aperçu des entreprises avec lesquelles nous collaborons, chacune étant 
          un expert reconnu dans son domaine respectif. Elles s'engagent à offrir le meilleur 
          rapport qualité-prix, soutenu par des garanties inclus pouvant s'étendre sur plus de 10 ans."
      />
      <Partners />
      <Reviews />
      <FAQs />
      <motion.a
        href={contacts.tel.to}
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.1 }}
        className='block text-2xl px-5 py-2 bg-slate-950 rounded-lg w-fit mx-auto'
      >
        Appeler
      </motion.a>
    </>
  )
}

export default Home
