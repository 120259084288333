import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LuPhoneCall } from "react-icons/lu"
import contacts from '../constants/contacts'
import { AnimatePresence, motion } from 'framer-motion'
import { realisationSection } from '../constants/routes'

const underlineVariants = {
  open: {
    transform: 'scaleX(1)'
  },
  close: {
    transform: 'scaleX(0)'
  }
}

const cardVariants = {
  open: {
    opacity: 1,
    transform: 'translateY(0px) translateX(-50%)',
  },
  close: {
    opacity: 0,
    transform: 'translateY(15px) translateX(-50%)',
  }
}

const mobileVariants = {
  close: {
    x: 1000
  }
}

const arrowVariants = {
  open: {
    rotate: 180,
  },
  close: {
    rotate: 0,
  }
}

const MobileNav = ({ setIsOpen, pathname }) => {
  const [isClicked, setIsClicked] = useState(false)
  const handleClick = () => {
    setIsClicked(prev => !prev)
  }

  return (
    <motion.nav
      variants={mobileVariants}
      initial='close'
      animate={{ x: 0 }}
      exit='close'
      className="fixed left-0 top-0 flex h-screen w-full flex-col bg-slate-900"
    >
      <div className="flex items-center justify-between p-6">
        <div className="flex items-center gap-2">
          <span className="sm:text-2xl font-bold">Fermetures Sans-souci</span>
        </div>
        <button onClick={() => setIsOpen(false)}>
          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-3xl text-white duration-300 hover:text-red-600 hover:rotate-90" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
      </div>
      <div className="h-screen overflow-y-scroll bg-neutral-100 p-6 text-neutral-900">
        <button
          onClick={() => setIsOpen(false)}
          className="flex w-full items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
        >
          <Link to="/">Accueil</Link>
        </button>
        <div className="relative">
          <button
            onClick={handleClick}
            className="flex w-full items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
          >
            <span>Nos Réalisations</span>
            <motion.span
              variants={arrowVariants}
              animate={isClicked ? 'open': 'close' }
            >
              <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-2xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </motion.span>
          </button>
          <AnimatePresence>
            {isClicked &&
              <motion.div
                initial={{ height: 0, marginBottom: 0 }}
                animate={{ height: "fit-content", marginBottom: 24 }}
                exit={{ height: 0, marginBottom: 0 }}
                className="overflow-hidden"
              >
                <div className="grid w-full grid-cols-12 shadow-xl lg:w-[750px]">
                  <div className="col-span-12 flex flex-col justify-between bg-indigo-600 p-6 lg:col-span-4">
                    <div className="mb-6">
                      <h2 className="mb-2 text-xl font-semibold text-white">{realisationSection.title}</h2>
                      <p className="text-sm text-indigo-100">{realisationSection.subtitle}</p>
                    </div>
                  </div>
                  <div className="col-span-12 grid grid-cols-2 gap-3 bg-white p-6 lg:col-span-8 lg:grid-cols-3">
                    {realisationSection.routes.map(route => (
                      <section key={route.title} className='space-y-3 flex flex-col'>
                        <h3 className="font-semibold">{route.title}</h3>
                          {route.links.map(link => (
                            <Link
                              key={link.text}
                              to={link.to}
                              onClick={() => setIsOpen(false)}
                              rel="nofollow"
                              className="block text-sm hover:underline hover:text-indigo-600"
                              aria-label={link.text}
                            >
                              {link.text}
                            </Link>
                          ))}
                      </section>
                    ))}
                  </div>
                </div>
              </motion.div>
            }
          </AnimatePresence>
          <Link
            to={contacts.tel.to}
            className="flex w-full items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
          >
            <span>{contacts.tel.value}</span>
          </Link>
        </div>
      </div>
    </motion.nav>
  )
}

const RealisationCard = () => (
  <motion.div
    variants={cardVariants}
    initial='close'
    animate='open'
    exit='close'
    className="absolute left-1/2 top-12 -translate-x-1/2 bg-white text-black"
  >
    <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
    <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
    <div className="grid w-full grid-cols-12 shadow-xl lg:w-[750px]">
      <div className="col-span-12 flex flex-col justify-between bg-indigo-700 p-6 lg:col-span-4">
        <div className="mb-6">
          <h2 className="mb-2 text-xl font-semibold text-white">{realisationSection.title}</h2>
          <p className="text-sm text-slate-100">{realisationSection.subtitle}</p>
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-2 gap-3 bg-white p-6 lg:col-span-8 lg:grid-cols-3">
        {realisationSection.routes.map(route => (
          <section key={route.title} className='space-y-3'>
            <h3 className="font-semibold">{route.title}</h3>
              {route.links.map(link => (
                <Link
                  key={link.text}
                  to={link.to}
                  rel="nofollow"
                  className="block text-sm hover:underline hover:text-indigo-600"
                  aria-label={link.text}
                >
                  {link.text}
                </Link>
              ))}
          </section>
        ))}
      </div>
    </div>
  </motion.div>
)

const NavBarElement = ({ navTitle, navLink, pathname, JsxCard = null }) => {
  const [onHover, setOnHover] = useState(false)

  const handleHover = () => {
    setOnHover(true)
  }

  const handleLeave = () => {
    setOnHover(false)
  }

  return (
    <motion.div
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      className="relative h-fit w-fit"
    >
      <Link to={navLink} className="relative">
        {navTitle}
        <motion.span
          variants={underlineVariants}
          animate={(onHover || pathname === navLink) ? 'open' : 'close'}
          className="absolute -bottom-2 -left-2 -right-2 h-px origin-left scale-x-0 rounded-full bg-indigo-300 transition-transform duration-300 ease-out"
        />
      </Link>
      {JsxCard !== null &&
        <AnimatePresence>
          {onHover && <JsxCard />}
        </AnimatePresence>
      }
    </motion.div>
  )
}

const NavBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useLocation()

  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }

  const handleMobileNav = () => {
    setIsOpen(prev => !prev)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <motion.nav
      className={`fixed top-0 z-40 w-full px-6 text-white transition-all duration-300 ease-out lg:px-12
        ${scrollPosition > 100 ? 'bg-slate-950 py-3 shadow-xl' : 'py-6 shadow-non'}
      `}
    >
      <div className="mx-auto flex max-w-3xl xl:max-w-5xl items-center justify-between">
        <div className="flex items-center gap-2">
          <Link
            to='/'
            className="sm:text-2xl text-lg font-bold text-white"
          >
            Fermetures Sans-souci
          </Link>
        </div>
        <div className="hidden gap-6 lg:flex">
          <div className="flex items-center gap-6">
            <NavBarElement navTitle='Accueil' navLink='/' pathname={pathname} />
            <NavBarElement navTitle='Nos Réalisations' navLink='/realisations' pathname={pathname} JsxCard={RealisationCard} />
            <a
              href={contacts.tel.to}
              className="flex gap-2 border-[1px] p-2 rounded-lg border-white w-fit transition-transform duration-300 active:scale-90 font-medium hover:scale-110"
            >
              <LuPhoneCall className='scale-125 my-auto' />
              {contacts.tel.value}
            </a>
          </div>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={handleMobileNav}
            className="block text-3xl hover:bg-white/70 hover:text-black rounded-full p-4"
          >
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="14" y2="18" />
            </svg>
          </button>
          <AnimatePresence mode='wait'>
            {isOpen && <MobileNav setIsOpen={setIsOpen} pathname={pathname} />}
          </AnimatePresence>
        </div>
      </div>
    </motion.nav>
  )
}

export default NavBar
