import { Link } from 'react-router-dom'
import { realisationSection } from '../constants/routes'

const VerticalLine = () => <hr className="w-[.5px] h-full bg-slate-400" />
const HorizontalLine = () => <hr className="h-[.5px] bg-slate-500 dark:bg-white/30 mt-20 shadow-[0px_0px_12px_white]" />

const Footer = () => {
  return (
    <footer className="pt-20 pb-20 mx-auto text-center bg-slate-950">
      <div className='flex lg:flex-row max-w-2xl flex-col gap-1 mx-auto'>
        {realisationSection.routes.map((route, i) => (
          <div key={route.title} className='flex w-full flex-row mx-auto'>
            <div className="space-y-2 mx-auto">
              <div className="text-white text-xl font-semibold mb-3">{route.title}</div>
              {route.links.map(link => (
                <Link
                  key={link.to}
                  to={link.to}
                  rel="nofollow"
                  className="block text-sm text-slate-300 hover:text-slate-50 hover:underline"
                  aria-label={link.aria}
                >
                  {link.text}
                </Link>
              ))}
            </div>
            {i < realisationSection.routes.length - 1 && <VerticalLine />}
          </div>
        ))}
      </div>
      <div className="max-w-7xl mx-auto text-slate-200">
        <HorizontalLine />
        <div className="mt-4 text-lg">Fermetures Sans-souci &copy; 2024</div>
        <div className='text-xs '>Tout droit réservé</div>
      </div>
    </footer>
  )
}

export default Footer
