const businessFields = [
  {
    id: 1,
    title: "Une Veranda ?",
    highlight: "Pour un espace convivial.",
    name: 'véranda',
    description: "Profitez d'un espace supplémentaire et polyvalent avec nos vérandas sur mesure. Créez un coin de détente lumineux ou une extension fonctionnelle de votre intérieur.",
    filename: 'veranda_1',
    alt: "Une véranda vitrée pour l'extention d'une maison.",
    photos: '/realisations',
    businessPage: '/verandas',
  },
  {
    id: 2,
    title: 'La Domotique ?',
    highlight: "Pour une maison sécurisée.",
    name: 'alarme et domotique',
    description: "Surveillez et contrôlez tous les aspects de votre domicile où que vous soyez, avec nos solutions domotiques intuitives et faciles à utiliser. Simplifiez votre quotidien et profitez d'un confort optimal.",
    filename: 'domotique_1',
    alt: "Un volet roulant qui s'ouvre grâce à notre solution domotique.",
    photos: '/realisations',
    businessPage: '/domotique',
  },
  {
    id: 3,
    title: 'Un Store Banne ?',
    highlight: "Pour un confort optimal.",
    name: "store",
    description: "Créez un espace extérieur ombragé et confortable avec notre gamme de stores bannes. Profitez de moments de détente à l'abri du soleil tout en ajoutant une touche d'élégance à votre terrasse ou jardin.",
    filename: 'store_1',
    alt: 'Un maison avec deux stores bannes jaunes et un store gris.',
    photos: '/realisations',
    businessPage: '/stores',
  },
  {
    id: 4,
    title: 'Un Volet Roulant ?',
    highlight: 'Pour une intimité préservée.',
    name: 'volet roulant',
    description: "Optez pour nos volets roulants pour préserver votre intimité tout en contrôlant la luminosité de votre intérieur. Une solution pratique et esthétique pour votre maison.",
    filename: 'volet_roulant_2',
    alt: 'Un volet roulant électrique noir pour une porte fenêtre.',
    photos: '/realisations',
    businessPage: '/volets-roulants',
  },
];

export default businessFields;
