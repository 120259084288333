import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
    <div className='grid place-content-center h-screen'>
      <div>
        <header>
          <h1 className='text-5xl text-white'>
            Erreur 404
          </h1>
          <p className='text-slate-300 text-lg'>Fermetures Sans-souci n'exerce pas dans cette catégorie</p>
        </header>
        <Link
          to='/'
          className="px-3 py-3 w-fit sm:px-7 sm:py-4 rounded-md sm:rounded-lg relative z-0 flex overflow-hidden whitespace-nowrap border-[1px] 
            border-neutral-500 font-medium text-slate-100 transition-all duration-500 hover:text-black
            before:absolute before:inset-0
            before:-z-10 before:translate-y-[200%]
            before:scale-[2] before:scale-y-[2.5]
            before:rounded-[100%] before:bg-indigo-600
            before:transition-transform before:duration-1000
            before:content-[&quot&quot]
            hover:scale-105 hover:border-indigo-600 hover:before:text-black
            hover:before:translate-y-[0%]
            active:scale-100"
        >
          retour à l'accueil
        </Link>
      </div>
    </div>
  )
}

export default Error404
