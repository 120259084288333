import React, { useRef } from 'react'
import { motion, useInView } from 'framer-motion'

const sectionVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: { duration: .6 }
  },
  close: {
    y: 100,
    opacity: 0
  }
}

export const TextSection = ({ id, title, text, ariaTitle, ariaText }) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <motion.section
      id={id}
      ref={ref}
      variants={sectionVariants}
      initial='close'
      animate={isInView && 'open'}
      className='w-full mt-12 pb-10'
    >
      <h2 className='text-xl sm:text-3xl md:text-5xl w-max mx-auto skew-x-12' aria-label={ariaTitle}>
        {title}
      </h2>
      <hr className='border-b-px border-indigo-600 w-24 md:w-32 mt-3 m-auto' aria-label="Divider" />
      <p className='text-slate-300 mt-16 md:max-w-5xl px-12 text-sm md:text-lg mx-auto text-justify' aria-label={ariaText}>
        {text}
      </p>
    </motion.section>
  )
}

export default TextSection
