import React from 'react'
import galeryImages from '../constants/galery-pictures'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async'
import { imagePath } from '../helpers/helpers'

const wrapperVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { staggerChildren: 0.15 }
  }
}

const imageVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}

const PictureGallery = () => {
  window.scrollTo({ top: 0 })
  return (
    <>
      <Helmet>
        <title>Fermetures Sans-souci - Votre professionnel de la fermeture en Essonnes</title>
        <meta name="description" content="Voici nos réalisations ! Faites le choix de vos prochains travaux parmis notre grande gamme de partenaires." />
      </Helmet>
      <div className='pt-20 mx-auto max-w-fit'>
        {galeryImages.map(content => (
          <section key={content.name} id={content.businessPage} className='p-10'>
            <h2>{content.name}</h2>
            <motion.ul
              variants={wrapperVariants}
              initial='hidden'
              animate='show'
              className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'
            >
              {content.images.map(img => (
                <li
                  key={img.filename}
                  className='w-[250px] h-[250px]'
                >
                  <motion.img
                    variants={imageVariants}
                    src={imagePath(`images/${img.filename}`)}
                    alt={img.alt}
                    width={250}
                    height={250}
                    loading='lazy'
                    className='hover:rounded-lg shadow rounded ease-in-out transition-all duration-500 scale-95 hover:scale-105 object-cover w-full h-full'
                  />
                </li>
              ))}
            </motion.ul>
          </section>
        ))}
      </div>
    </>
  )
}

export default PictureGallery
