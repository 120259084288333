export const checkedList = [
  {
    id: 1,
    text: "Devis 100% gratuit !",
  },
  {
    id: 2,
    text: "Installation de systèmes domotiques.",
  },
  {
    id: 3,
    text: "Remplacement de fenêtres, velux, vitres et vitrines.",
  },
  {
    id: 4,
    text: "Pose, réglage et réparation de store et volet roulant.",
  },
  {
    id: 5,
    text: "Intervention en urgence !",
  }
];