import galeryImages, { ImageType } from "./galery-pictures";

const secondaryPageData = [
  {
    id: 1,
    link: 'domotique',
    data: {
      helmet: {
        title: "Domotique",
        description: "Découvrez nos solutions domotiques pour rendre votre maison plus intelligente et connectée. Contrôlez vos équipements électriques, de sécurité et de confort à distance, pour une vie quotidienne plus facile et plus sécurisée.",
        keywords: "domotique, domotique maison, maison connectée, installation domotique"

      },
      title: "Domotique",
      description: "Transformez votre maison en maison connectée avec notre gamme de solutions domotiques. Contrôlez l'éclairage, le chauffage, les volets et bien plus encore depuis votre smartphone ou votre tablette. Profitez d'une vie plus confortable, sécurisée et économe en énergie grâce à la domotique.",
      images: galeryImages.find(element => element.type === ImageType.DOMOTIQUE).images
    }
  },
  {
    id: 2,
    link: 'telecommandes',
    data: {
      helmet: {
        title: "Telecommandes",
        description: "Découvrez notre gamme de télécommandes pour automatiser et contrôler facilement vos équipements. Des solutions pratiques et innovantes pour simplifier votre quotidien.",
        keywords: "telecommandes, telecommande, telecommande sans fil, telecommande universelle, commande a distance"
      },
      title: "Telecommandes",
      description: "Explorez notre sélection de télécommandes sans fil pour commander vos équipements à distance avec facilité. Que ce soit pour vos volets roulants, vos stores bannes ou tout autre appareils électroniques, nos télécommandes vous offrent un contrôle pratique et intuitif.",
      images: galeryImages.find(element => element.type === ImageType.TELECOMMANDE).images
    }
  },
  {
    id: 3,
    link: 'digicodes',
    data: {
      helmet: {
        title: "Nos digicodes",
        description: "Découvrez nos solutions de digicodes pour sécuriser l'accès à votre propriété de manière pratique et efficace. Profitez d'une gamme de digicodes de haute qualité, faciles à installer et à utiliser, pour contrôler l'entrée et la sortie de votre domicile ou de votre entreprise.",
        keywords: "digicode, digicode portail, digicode porte, digicode connecté"
      },
      title: "Digicodes",
      description: "Explorez notre sélection de digicodes conçus pour offrir une sécurité accrue et une gestion simplifiée des accès à votre propriété. Nos digicodes sont dotés de fonctionnalités avancées telles que la programmation horaire, les codes temporaires et les alertes d'intrusion, pour vous offrir une tranquillité d'esprit totale.",
      images: galeryImages.find(element => element.type === ImageType.DIGICODE).images
    }
  },
  {
    id: 4,
    link: 'alarmes',
    data: {
      helmet: {
        title: "Systèmes d'Alarme",
        description: "Découvrez nos systèmes d'alarme pour protéger votre maison et votre famille contre les intrusions et les cambriolages. Profitez d'une tranquillité d'esprit totale grâce à nos solutions de sécurité fiables et faciles à utiliser.",
        keywords: "alarme, alarme maison, systeme alarme, devis alarme maison, installation alarme, alarme maison sans fil"
      },
      title: "Systèmes d'Alarme",
      description: "Protégez votre maison avec nos systèmes d'alarme de pointe. Nos solutions de sécurité intelligentes vous avertissent en cas d'intrusion et vous permettent de réagir rapidement en cas d'urgence. Avec des fonctionnalités telles que la détection de mouvement, la surveillance vidéo et le contrôle à distance, vous pouvez dormir tranquille en sachant que votre maison est sécurisée.",
      images: galeryImages.find(element => element.type === ImageType.ALARME).images
    }
  },
  {
    id: 5,
    link: 'portes-garage',
    data: {
      helmet: {
        title: "Nos portes de garage",
        description: "Découvrez nos solutions de portes de garage pour sécuriser et embellir votre propriété. Profitez d'une gamme variée de portes de garage de haute qualité, allant des portes sectionnelles aux portes basculantes, pour répondre à vos besoins spécifiques en matière de sécurité et de design.",
        keywords: "porte garage, porte de garage, porte de garage sectionnelle, porte de garage enroulable, porte de garage basculante, porte de garage sur mesure, devis porte de garage"
      },
      title: "Portes de garage",
      description: "Explorez notre sélection de portes de garage conçues pour offrir sécurité, commodité et esthétique à votre maison. Que vous recherchiez une porte de garage robuste pour protéger vos véhicules ou une porte de garage élégante pour améliorer l'aspect extérieur de votre propriété, nous avons des options adaptées à chaque style et budget.",
      images: galeryImages.find(element => element.type === ImageType.PORTEGARAGE).images
    }
  },
  {
    id: 6,
    link: 'volets-roulants',
    data: {
      helmet: {
        title: "Nos volets roulants sur mesure",
        description: "Découvrez nos volets roulants sur mesure pour plus de confort, de sécurité et d'efficacité énergétique. Fabriqués avec des matériaux de haute qualité et équipés de fonctionnalités avancées, nos volets roulants offrent une solution de qualité pour votre maison.",
        keywords: "volet roulant, volet roulant solaire, volet roulant electrique, pose volet roulant, volet velux, volet solaire velux, volet roulant manuel"
      },
      title: "Nos volets roulants sur mesure",
      description: "Explorez notre gamme de volets roulants sur mesure conçus pour répondre à vos besoins spécifiques. Que vous recherchiez une meilleure isolation thermique, une obscurité totale ou une protection contre les intrusions, nos volets roulants offrent polyvalence et performance.",
      images: galeryImages.find(element => element.type === ImageType.VOLETROULANT).images
    }
  },
  {
    id: 7,
    link: 'stores',
    data: {
      helmet: {
        title: "Nos stores extérieurs et intérieurs",
        description: "Découvrez nos stores extérieurs et intérieurs pour contrôler la lumière, protéger votre intimité et ajouter du style à votre espace. Avec une variété de modèles et de fonctionnalités disponibles, nos stores offrent à la fois esthétique et fonctionnalité.",
        keywords: "store, store banne, store banne motorisé, store exterieur, store ban electrique, store electrique, store sur mesure, store terrasse, store banne solaire"
      },
      title: "Stores",
      description: "Trouvez le store parfait pour votre maison parmi notre sélection de modèles haut de gamme. Que vous recherchiez un store banne pour votre terrasse, un store vénitien pour votre salon ou un store enrouleur pour votre chambre, nous avons des solutions pour tous les besoins.",
      images: galeryImages.find(element => element.type === ImageType.STORE).images
    }
  },
  {
    id: 8,
    link: 'portes-entrees',
    data: {
      helmet: {
        title: "Nos portes d'entrée",
        description: "Découvrez nos portes d'entrée haut de gamme pour ajouter du style et de la sécurité à votre maison. Avec une gamme de designs et de finitions disponibles, nos portes d'entrée sur mesure s'adaptent à tous les styles architecturaux.",
        keywords: "porte d entree, porte d entrée, porte d entrée blindée, "
      },
      title: "Nos portes d'entrée",
      description: "Que vous cherchiez à agrandir votre espace de vie, à créer un coin détente lumineux ou à profiter d'une vue imprenable sur votre jardin, nos vérandas offrent une solution élégante et fonctionnelle. Contactez-nous dès aujourd'hui pour obtenir votre devis personnalisé.",
      images: galeryImages.find(element => element.type === ImageType.PORTEENTREE).images
    }
  },
  {
    id: 9,
    link: 'verandas',
    data: {
      helmet: {
        title: "Nos vérandas sur mesure",
        description: "Découvrez nos vérandas sur mesure, conçues pour ajouter du style et de l'espace à votre maison. Profitez de la lumière naturelle et de vues panoramiques tout en créant un espace confortable pour toute la famille. Demandez votre devis dès maintenant.",
        keywords: "veranda, verandas, véranda, veranda sur mesure, devis veranda"
      },
      title: "Nos vérandas sur mesure",
      description: "Transformez votre espace de vie avec nos vérandas sur mesure. Conçues pour fusionner harmonieusement avec votre maison, nos vérandas offrent une abondance de lumière naturelle et une vue imprenable sur votre jardin. Créez un espace polyvalent pour vous détendre, recevoir des invités ou simplement profiter de la nature, quelles que soient les conditions météorologiques. Découvrez nos designs élégants et fonctionnels, et laissez-nous vous aider à réaliser votre vision de l'espace de vie idéal.",
      images: galeryImages.find(element => element.type === ImageType.VERANDA).images
    }
  },
  {
    id: 10,
    link: 'fenetres',
    data: {
      helmet: {
        title: "Fenêtres et Velux",
        description: "Découvrez nos réalisations de fenêtres pour améliorer l'esthétique, l'isolation et la sécurité de votre maison. Choisissez parmi une variété de matériaux et de styles pour répondre à vos besoins spécifiques en matière de design et de performance.",
        keywords: "fenetre, fenetre pvc, fenetre de toit, velux, fenetre double vitrage, devis fenetre, changer fenetre, fenetre sur mesure, pose fenetre"
      },
      title: "Fenêtres et Velux",
      description: "Explorez notre gamme de fenêtres pour votre maison. Des fenêtres en PVC aux fenêtres en aluminium, nous offrons une variété de styles et de matériaux pour répondre à vos besoins en matière d'esthétique, d'isolation et de sécurité. Profitez d'une luminosité naturelle et d'une isolation thermique optimale avec nos solutions de fenêtres.",
      images: galeryImages.find(element => element.type === ImageType.FENETRE).images
    }
  },
  {
    id: 11,
    link: 'pergolas',
    data: {
      helmet: {
        title: "Nos pergolas",
        description: "Découvrez nos solutions de pergolas pour créer des espaces extérieurs élégants et fonctionnels. Profitez d'une gamme de pergolas sur mesure, conçues pour offrir ombre, abri et style à votre jardin, terrasse ou patio.",
        keywords: "pergola, pergola alu, pergola bois, pergola pas cher, pergola terrasse"
      },
      title: "Pergolas",
      description: "Explorez notre sélection de pergolas conçues pour créer des espaces de vie extérieurs confortables et accueillants. Que vous recherchiez une pergola autoportée, adossée ou bioclimatique, nous avons des options adaptées à chaque style et besoin. Nos pergolas sont fabriquées avec des matériaux durables pour résister aux intempéries et offrir une longue durabilité.",
      images: galeryImages.find(element => element.type === ImageType.PERGOLA).images
    }
  }
];

export default secondaryPageData;
