import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import businessFields from '../constants/business-fields'
import { imagePath } from '../helpers/helpers'
import { Link } from 'react-router-dom'

const ImageContent = ({ filename, imageAlt, i }) => (
  <div className='relative lg:w-1/2 px-2 lg:p-0'>
    <img
      className={`${i % 2 === 0
          ? 'picture-deformation-r lg:rounded-r-lg mr-auto'
          : 'picture-deformation-l lg:rounded-l-lg ml-auto'
        } object-cover rounded-lg sm:w-[500px] w-[400px] aspect-square	`
      }
      src={imagePath(`images/${filename}`)}
      alt={imageAlt}
      loading='lazy'
    />
  </div>
)

const ContentElement = ({ field, i }) => {
  const wrapperRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: wrapperRef,
    offset: ['start end', 'center center']
  })
  const fromRight = useTransform(scrollYProgress, [0, .8], ['-50%', '0%'])
  const fromLeft = useTransform(scrollYProgress, [0, .8], ['50%', '0%'])
  const isOdd = i % 2 !== 0

  return (
    <motion.section
      className='relative overflow-hidden max-w-6xl mx-auto lg:my-auto flex flex-col lg:flex-row'
      ref={wrapperRef}
      style={{ translateX: isOdd ? fromRight : fromLeft }}
    >
      {isOdd && <ImageContent filename={field.filename} imageAlt={field.alt} i={i} />}
      <div className='absolute top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2 z-10
        lg:relative lg:top-auto lg:right-auto lg:translate-y-0 lg:translate-x-0 lg:w-1/2'>
        <div className='flex my-auto flex-col justify-center h-full rounded-lg px-2 sm:px-12 py-3 bg-slate-800/70 lg:bg-transparent'>
          <h2 className='text-2xl sm:text-4xl xl:text-6xl tracking-tight text-slate-100 lg:text-left'>
            {field.title}
          </h2>
          <span className='text-yellow-500 text-2xl sm:text-4xl xl:text-5xl font-extrabold'>
            {field.highlight}
          </span>
          <p className='mt-3 text-xs sm:text-base mx-5 text-slate-100 lg:text-slate-200 sm:mt-5 md:text-lg sm:max-w-xl lg:text-left md:mt-5 xl:text-xl'>
            {field.description}
          </p>
          <div className='mt-5 sm:mt-8 flex justify-center lg:justify-start gap-3'>
            <Link
              to={field.photos}
              className="px-3 py-3 sm:px-7 sm:py-4 rounded-md sm:rounded-lg relative z-0 flex overflow-hidden items-center gap-2 whitespace-nowrap border-[1px] 
                border-slate-300 font-medium
                text-slate-100 transition-all duration-300
                before:absolute before:inset-0
                before:-z-10 before:translate-y-[200%]
                before:scale-[2.5]
                before:rounded-[100%] before:bg-slate-600
                before:transition-transform before:duration-1000
                before:content-[&quot;&quot;]
                hover:scale-105 hover:border-slate-600 hover:text-white
                hover:before:translate-y-[0%]
                active:scale-100"
            >
              voir les photos
            </Link>
            <Link
              to={field.businessPage}
              className={`px-3 py-3 sm:px-7 sm:py-4 rounded-md sm:rounded-lg relative z-0 flex overflow-hidden items-center gap-2 whitespace-nowrap border-[1px] 
              border-slate-600 font-medium
              text-slate-100 transition-all duration-300
                before:absolute before:inset-0 before:-z-10
                before:translate-x-[0%] before:translate-y-[0%]
                before:scale-[2.5]
                before:rounded-[100%] before:bg-slate-600
                before:transition-transform before:duration-1000
                before:content-[&quot;&quot;]
                hover:scale-105 hover:border-slate-300 hover:text-slate-100
                hover:before:translate-y-[-200%] hover:before:translate-x-[${isOdd ? '' : '-' }200%]
                active:scale-100`}
            >
              en savoir plus
            </Link>
          </div>
        </div>
      </div>
      {!isOdd && <ImageContent filename={field.filename} imageAlt={field.alt} i={i} />}
    </motion.section>
  )
}

const ScrollContent = () => {
  return (
    <motion.div
      className='xl:max-w-7xl lg:max-w-5xl mx-auto overflow-hidden flex flex-col gap-12 my-20'
    >
      {businessFields.map(field => (
        <ContentElement key={field.id} field={field} i={field.id - 1} />
      ))}
    </motion.div>
  )
}

export default ScrollContent
