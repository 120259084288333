export const reviews = [
  {
    id: 1,
    rating: 5,
    username: "Tom",
    userProfile: "",
    publishedTime: "2024-02-01T08:00:00Z",
    feedback: "Produit de qualité, tarif raisonnable,pose rapide et efficace, Mr Martins est très professionnel."
  },
  {
    id: 2,
    rating: 5,
    username: "Lorraine Theodore",
    userProfile: "",
    publishedTime: "2021-02-01T08:00:00Z",
    feedback: "Mr Martins a effectué du très bon travail en changeant toutes les fenêtres de notre appartement. Je recommande."
  },
  {
    id: 3,
    rating: 5,
    username: "S. Martins",
    userProfile: "",
    publishedTime: "2020-02-01T08:00:00Z",
    feedback: "Entreprise sérieuse et pose de matériel de qualité par une bonne équipe professionnelle. Excellent rapport qualité prix."
  },
  {
    id: 4,
    rating: 5,
    username: "Bertrand Marin",
    userProfile: "",
    publishedTime: "2021-02-01T08:00:00Z",
    feedback: "Super professionnel ! Rapide, réactif, à l'écoute et super sympa."
  },
  {
    id: 5,
    rating: 5,
    username: "Baptiste Cheio",
    userProfile: "",
    publishedTime: "2020-02-01T08:00:00Z",
    feedback: "Merci Mr. Martins de vôtre intervention. Je vous recommande sans hésitation 😊 …"
  },
  {
    id: 6,
    rating: 5,
    username: "michele hermet",
    userProfile: "",
    publishedTime: "2024-02-01T08:00:00Z",
    feedback: "Travail soigné."
  },
];