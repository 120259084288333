import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import NavBar from './components/NavBar'
import { HelmetProvider } from 'react-helmet-async'
import { useScroll, useSpring, motion } from 'framer-motion'
import Router from './Router'
import Footer from './components/Footer'

export const App = () => {
  const { scrollYProgress } = useScroll()
  const scaleY = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  return (
    <HelmetProvider>
      <BrowserRouter>
        <NavBar />
        <main className='text-slate-100 bg-slate-800 pb-10'>
          <div className='mx-auto'>
            <div className="fixed z-50 right-0 top-px w-1 h-screen bg-slate-600 origin-top" />
            <motion.div className="fixed z-50 right-0 top-px w-1 h-screen bg-slate-200 origin-top" style={{ scaleY }} />
            <Router />
          </div>
        </main>
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App
