import React from 'react'
import { Link } from 'react-router-dom'
import TextSection from './TextSection'
import { FaUser } from 'react-icons/fa'
import { reviews } from '../constants/reviews'

const Star = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <mask id={`fillMask${fill}`}>
        <rect x="0" y="0" width={`${fill * 100}%`} height="100%" fill="#FFFFFF" />
        <rect x="0" y="0" width={`${(fill) * 100}%`} height="100%" fill="#FFFFFF" />
        <rect x={`${(fill) * 100}%`} y="0" width={`${(1 - fill) * 100}%`} height="100%" fill="#222222" />
      </mask>
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" fill="#FACC15" mask={`url(#fillMask${fill})`} />
    </svg>
  )
}

const CustomerReview = ({ rating, username, publishedTime, feedback }) => {
  const calculateTimeSincePublished = (publishedTime) => {
    const currentTime = new Date()
    const reviewTime = new Date(publishedTime)
    const difference = Math.floor((currentTime - reviewTime) / (1000 * 60 * 60 * 24))
    switch (true) {
      case difference === 0: return "Aujourd'hui"
      case difference === 1: return "Il y a 1 jour"
      case difference < 7: return `Il y a ${difference} jours`
      case difference < 30:
        const weeks = Math.floor(difference / 7)
        return weeks === 1 ? 'Il y a une semaine' : `il y a ${weeks} semaines`
      case difference < 365:
        const months = Math.floor(difference / 30)
        return months === 1 ? 'Il y a 1 mois' : `Il y a ${months} mois`
      default:
        const years = Math.floor(difference / 365)
        return years === 1 ? 'Il y a 1 an' : `Il y a ${years} ans`
    }
  }
  const formattedTime = calculateTimeSincePublished(publishedTime)

  const renderStars = (rating) => {
    const stars = []
    const rest = rating - Math.floor(rating)
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<Star key={i} fill={1} />)
      } else if  (i < Math.ceil(rating) && rest > 0) {
        stars.push(<Star key={i} fill={rest} />)
      } else {
        stars.push(<Star key={i} fill={0} />)
      }
    }
    return stars
  }

  return (
    <Link to='https://www.google.com/maps/place/Sans+Souci/@48.5968096,2.4723074,17z/data=!3m1!4b1!4m6!3m5!1s0x47e5e721459d8ff9:0x43a741105f3ef661!8m2!3d48.5968096!4d2.4748877!16s%2Fg%2F11hcdw44_2?entry=ttu'
      className="relative mx-auto w-full sm:w-96 p-4 transform hover:translate-x-1 hover:-translate-y-1 transition duration-300 bg-slate-900 rounded-lg m-3 hover:shadow-[-6px_6px_2px_black]"
    >
      <div className="flex items-center mb-2">
        <FaUser className='mr-2 bg-slate-300 rounded-full h-10 w-10 sm:h-16 sm:w-16 text-black p-1 pt-4' />
        <div className=''>
          <span className="block font-semibold sm:text-lg mb-1">{username}</span>
          <span className="absolute top-1 sm:top-4 right-4 text-sm sm:text-base text-slate-300">{formattedTime}</span>
          <div className="flex items-center mb-2">
            {renderStars(rating)}
          </div>
        </div>
      </div>
      <p className="text-sm text-slate-200">{feedback}</p>
    </Link>
  )
}

const Reviews = () => {
  return (
    <div className='w-full'>
      <TextSection
        id='review'
        title="Des Clients Satisfaits !"
        text="Chez Fermetures Sans-souci, la satisfaction de nos clients est notre priorité absolue. Nous sommes fiers de fournir un service exceptionnel et de répondre aux besoins de nos clients de manière efficace et professionnelle. Découvrez ce que nos clients satisfaits ont à dire sur leur expérience avec Fermetures Sans-souci."
      />
      <section className="max-w-5xl mx-auto flex flex-wrap px-10 mt-14">
        {reviews
          .slice()
          .sort(() => 0.5 - Math.random())
          .slice(0, 3)
          .map((review, i) => (
            <CustomerReview
              key={review.id}
              rating={review.rating}
              username={review.username}
              publishedTime={review.publishedTime}
              feedback={review.feedback}
            />
          ))
        }
      </section>
    </div>
  )
}

export default Reviews